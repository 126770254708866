<template>
    <div style="padding: 10px">
        <div>
            <div class="flex flex-wrap justify-around  align-center" style="height: 100%;padding: 10px">
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(45, 140, 240)">日</div>
                    <div class="total-info">
                        <div class="total-info-num">{{formatNumber(toDaySum)}}</div>
                        <div class="total-info-txt">今日收入</div>
                    </div>
                </div>
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(25, 190, 107)">周</div>
                    <div class="total-info">
                        <div class="total-info-num">{{formatNumber(monthSum)}}</div>
                        <div class="total-info-txt">本月收入</div>
                    </div>
                </div>
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(228, 108, 187);">月</div>
                    <div class="total-info">
                        <div class="total-info-num">{{formatNumber(yearSum)}}</div>
                        <div class="total-info-txt">年度收入</div>
                    </div>
                </div>
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(154, 102, 228)">总</div>
                    <div class="total-info">
                        <div class="total-info-num">{{formatNumber(totalSum)}}</div>
                        <div class="total-info-txt">总收入</div>
                    </div>
                </div>
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(255, 153, 0)">退</div>
                    <div class="total-info">
                        <div class="total-info-num">{{toDayRefund}}</div>
                        <div class="total-info-txt">今日退费</div>
                    </div>
                </div>
                <div class="flex align-center total-box">
                    <div class="total-name" style="background: rgb(237, 63, 20)">退</div>
                    <div class="total-info">
                        <div class="total-info-num">{{totalRefund}}</div>
                        <div class="total-info-txt">总退费</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div style="padding: 20px 0">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        size="mini"
                        v-model="date"
                        type="month"
                        @change="change"
                        placeholder="选择月">
                </el-date-picker>
            </div>
        </div>
        <div class=""  v-loading="loading">
            <div id="everyday" style="height: 600px;width: 100%;margin: 20px 0"></div>

        </div>

        <div>统计排行</div>
        <div style="margin-top: 20px">
            <el-date-picker
                    size="mini"
                    v-model="value2"
                    type="daterange"
                    align="right"
                    unlink-panels
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    @change="changeRankingDay"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
            </el-date-picker>
        </div>
        <div style="display: flex;justify-content: space-between;justify-items: center" v-loading="loading2">
            <div id="main2" style="height: 600px;width: calc(100% - 380px);margin: 20px 0" ></div>
            <div>
                <el-table
                        border
                        size="mini"
                        stripe
                        show-summary
                        :data="tableData"
                        :default-sort = "{prop: 'index', order: 'descending'}"
                        max-height="600"
                        style="width:380px;overflow: auto">
                    <el-table-column
                            label="排名"
                            type="index"
                            style="text-align: center"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            prop="user_name"
                            label="姓名"
                            width="180">
                        <template slot-scope="scope">
                                <p style="font-size: 12px">{{ scope.row.user_name }}</p>
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="total_price"
                            label="金额">
                        <template slot-scope="scope">
                            <p style="font-size: 12px">{{ scope.row.total_price }}</p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "salesStatisticsChart",
        data(){
            return {
                ranking:'day',
                loading:false,
                loading2:false,
                toDaySum: 0,
                monthSum: 0,
                yearSum: 0,
                totalSum: 0,
                toDayRefund: 0,
                totalRefund: 0,
                date: new Date(),
                everyday:null,
                myChart2:null,
                tableData:[],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: [new Date(),new Date()]
            };
        },
        methods:{
            ...mapActions('sales',['getStatisticsChart','getStatisticsRanking']),
            formatNumber(num) {
                let n = Number(num);
                if (n === 0) {
                    return num + '';
                } else
                if (n > 1 && n < 10000) {
                    return n + '';
                } else {
                    return (n / 10000).toFixed(3) + '万';
                }
            },
            changeRankingDay(e){
                this.getRanking()
            },
            change(e){
                console.log(e)
                this.getDetail()
            },
            async getDayChart(){
                this.loading = true
                let res = await this.getStatisticsChart({date:this.date})
                this.loading = false
                this.toDaySum = res.data.toDaySum
                this.monthSum = res.data.monthSum
                this.yearSum = res.data.yearSum
                this.totalSum = res.data.totalSum
                this.toDayRefund = res.data.toDayRefund
                this.totalRefund = res.data.totalRefund
                let users = []
                let day = []

                let userData = []
                let da = []
                let total = 0
                let array = []
                let totalArr = []
                res.data.allUser.forEach(user => {
                    users.push(user.name)
                    for (let resKey in res.data.dayStatistics) {
                        if(!day.includes(resKey)) day.push(resKey)

                        res.data.dayStatistics[resKey].forEach(item => {
                            item.forEach(i => {
                                if(user.id == i.user_id){
                                    da.push(i.price)
                                }
                            })
                        })
                    }
                    userData.push({
                        name:user.name,
                        type:'bar',
                        stack:'Total',
                        // label: {
                        //     show: true,
                        //     position: 'top',
                        // },
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        data:da
                    })
                    da = []

                })
                let i=0

                for (let resKey in res.data.dayStatistics) {
                    userData.forEach(user => {
                        if(user.data[i])
                            total += user.data[i]
                    })
                    i++
                    totalArr.push(total)
                    total = 0
                }
                i=0
                userData[userData.length-1].label = {
                    show: true,
                    position: 'top',
                    formatter:function(p){
                        return totalArr[p.dataIndex]
                    }
                }
                let option = {
                    title: {
                        text: ''
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                    },
                    legend: {
                        data: users
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                    },
                    xAxis: {
                        type: 'category',
                        data: day
                    },
                    yAxis: {
                        type: 'value',
                        max:function (value) {
                            return value.max + 50000;
                        }
                    },
                    series: userData
                };
                let everyday = this.$echarts.init(document.getElementById('everyday'));
                everyday.setOption(option,true);
                option = null

            },
            async getRanking(){
                this.loading2 = true
                let ranking = await this.getStatisticsRanking({startDay:this.value2[0],endDay:this.value2[1]})
                console.log(ranking.data)
                this.tableData = ranking.data
                let users = []
                let userTotalPrice = []
                if(ranking.data.length>0){
                    ranking.data.forEach(item => {
                        users.push(item.user_name)
                        if(item.total_price<0){
                            userTotalPrice.push({
                                value: item.total_price,
                                itemStyle: {
                                    color: '#a90000'
                                }
                            })
                        }else{
                                userTotalPrice.push(item.total_price)
                         }

                    })
                }
                console.log(userTotalPrice)
                let option2  = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: users
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: users,
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '总业绩',
                            type: 'bar',
                            stack: 'Total',
                            label: {
                                show: true,
                                position: 'inside'
                            },
                            data: userTotalPrice
                        }
                    ]
                }

                let myChart2 = this.$echarts.init(document.getElementById('main2'));
                myChart2.setOption(option2);
                option2 = null
                this.loading2 = false
            },
            getDetail(){
                this.getDayChart()
                this.getRanking()
            }

        },
        async mounted() {
            this.getDetail()
        }
    }
</script>

<style scoped>
    .flex{
        display: flex;
    }
    .flex-wrap{
        flex-wrap: wrap;
    }
    .align-center{
        align-items: center;
    }
    .justify-around{
        justify-content : space-between;
    }
    .total-box{
        height: 120px;
        width: calc(16.6% - 20px);
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
        border-radius: 8px;
    }
    .total-name{
        font-size: 30px;
        color: rgb(255, 255, 255);
        text-align: center;
        border-radius: 8px 0 0 8px;
        background: #cccccc;
        width: 30%;
        height: 120px;
        line-height: 120px;
    }
    .total-info{
        width: calc(70%);
        text-align: center;
        height: 100%;

    }
    .total-info-num{
        font-size: 20px;
        line-height: 85px;
    }
    .total-info-txt{

    }

</style>
